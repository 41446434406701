import { ErrorProp, Organization } from '@models';
import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
export const UPDATE_LOADING_ACTION = '[organization] loading update';
export const ADD_ORGANIZATION_ACTION = '[organizations page] add organization';
export const ADD_ORGANIZATION_SUCCESS =
  '[organizations page] add organization success';
export const UPDATE_ORGANIZATION_ACTION =
  '[organizations page] update organization';
export const UPDATE_ORGANIZATION_SUCCESS =
  '[organizations page] update organization success';
export const ERASE_ORGANIZATION_ACTION =
  '[organizations page] erase organization';
export const HANDLE_ERROR = '[organization] handle error';
export const LOAD__ORGANIZATION = '[organizations page] load organizations';
export const LOAD__ORGANIZATION_SUCCESS =
  '[organizations page] load organizations success';
export const CLEAN_ORGANIZATION = '[organization] clean organization';
export const VIEW_ORGANIZATION = '[organization] view organization';
export const viewOrganization = createAction(
  VIEW_ORGANIZATION,
  props<{ id: Organization['id'] }>()
);

export const addOrganization = createAction(
  ADD_ORGANIZATION_ACTION,
  props<{
    data: FormData;
    ref?: string;
  }>()
);
export const addOrganizationSuccess = createAction(
  ADD_ORGANIZATION_SUCCESS,
  props<{ data: Organization }>()
);
export const eraseRecord = createAction(
  UPDATE_ORGANIZATION_SUCCESS,
  props<{ id: string }>
);
export const loadOrganization = createAction(LOAD__ORGANIZATION);
export const loadOrganizationSuccess = createAction(
  LOAD__ORGANIZATION_SUCCESS,
  props<{ data: Organization[] }>()
);
export const updateOrganization = createAction(
  UPDATE_ORGANIZATION_ACTION,
  props<{ data: FormData; id: string; message?: string; ref?: string }>()
);
export const updateOrganizationSuccess = createAction(
  UPDATE_ORGANIZATION_SUCCESS,
  props<{ data: Update<Organization> }>()
);

export const handleFailed = createAction(
  HANDLE_ERROR,
  props<{ errorProps: ErrorProp }>()
);
export const updateLoading = createAction(
  UPDATE_LOADING_ACTION,
  props<{ isLoading: boolean }>()
);
export const cleanOrganization = createAction(
  CLEAN_ORGANIZATION,
  props<{ id: Organization['id'] }>()
);
